import {
  Anchor,
  Box,
  Breadcrumbs as MantineBreadcrumbs,
  Card,
  Divider,
  Group,
  Notification,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { Fragment, ReactNode } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth } from "../auth/useAuth";
import { useDocumentationStore } from "../stores/DocumentationStore";

export const PageHeader = ({
  title,
  subTitle,
  subTitleComponent,
  right,
  children,
  hasTabs,
  inline = false,
  visible = true,
  breadcrumbs,
}: {
  title?: string;
  subTitle?: string;
  subTitleComponent?: ReactNode;
  right?: ReactNode;
  children?: ReactNode;
  hasTabs?: boolean;
  inline?: boolean;
  visible?: boolean;
  breadcrumbs?: { title: string; href: string }[];
}) => {
  return visible ? (
    <>
      <Card mb="lg">
        <Card.Section withBorder inheritPadding py="sm">
          <Group justify="space-between">
            <Title order={4} mb={0}>
              {title}
              {subTitle && (
                <Text c="dimmed" size="sm" fw="semibold">
                  {subTitle}
                </Text>
              )}
              {subTitleComponent}
              <BreadcrumbsNavigation breadcrumbs={breadcrumbs} />
            </Title>
            {right && (
              <Box style={{ alignSelf: "flex-start" }}>
                <Group align="center">{right}</Group>
              </Box>
            )}
          </Group>
        </Card.Section>
        {children && (
          <Card.Section pt="xs" px="xs" pb={hasTabs ? 0 : "xs"}>
            {children}
          </Card.Section>
        )}
      </Card>
      {inline && <Divider mt={0} mb="sm" />}
      <DocumentationNotification />
    </>
  ) : null;
};

const DocumentationNotification = () => {
  const documentationStore = useDocumentationStore();
  const { authUser } = useAuth();
  const location = useLocation();
  return (
    <>
      {documentationStore.docPage &&
      documentationStore.docPage.pathname === location.pathname &&
      documentationStore.docPage?.sections?.length &&
      documentationStore.docPage?.sections?.length > 0 ? (
        <Stack>
          {documentationStore.docPage?.sections
            ?.filter(
              (section) =>
                section.autoLoad === true &&
                (documentationStore.docPage?.orgType === authUser?.orgType ||
                  documentationStore.docPage?.orgType === "BUILDER_SUB"),
            )
            .map((section, index) => (
              <Notification
                withCloseButton={false}
                key={index}
                title={section.title}
                color="blue"
                mb="lg"
              >
                {section.body}
              </Notification>
            ))}
        </Stack>
      ) : null}
    </>
  );
};

const BreadcrumbsNavigation = ({
  breadcrumbs,
}: {
  breadcrumbs?: { title: string; href: string }[];
}) => {
  if (!breadcrumbs) {
    return null;
  }

  return (
    <MantineBreadcrumbs fz="sm">
      {breadcrumbs.map((breadcrumb, index) => (
        <Fragment key={index}>
          {breadcrumb.href === "#" ? (
            <Text
              size="sm"
              c="dimmed"
              key={breadcrumb.title}
              style={{ marginRight: 5 }}
            >
              {breadcrumb.title}
            </Text>
          ) : (
            <Anchor
              size="sm"
              component={NavLink}
              key={breadcrumb.title}
              to={breadcrumb.href}
            >
              {breadcrumb.title}
            </Anchor>
          )}
        </Fragment>
      ))}
    </MantineBreadcrumbs>
  );
};
