import axios from "axios";


export const AIService = {
    async generateText(text: string, prompt: string = "rewrite, give me the first result only."): Promise<string> {
        const response = await axios.get('/common/ai/generate-text', {
            params: {
                text,
                prompt
            }
        });
        return response.data;
    }
}