import {
  Anchor,
  Badge,
  Checkbox,
  ColorSchemeScript,
  DrawerContent,
  DrawerTitle,
  Indicator,
  LoadingOverlay,
  MantineProvider,
  ModalContent,
  ModalTitle,
  Notification,
  Paper,
  createTheme,
} from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import React, { FC } from "react";
import { I18nextProvider } from "react-i18next";
import keycloak from "./auth/keycloak";
import { AuthProvider } from "./auth/useAuth";
import AppRoutes from "./components/layout/routes/_appRoutes";
import i18n from "./i18n/i18n";

const theme = createTheme({
  components: {
    LoadingOverlay: LoadingOverlay.extend({
      defaultProps: {
        overlayProps: {
          radius: "sm",
          blur: 2,
          opacity: 0.75,
        },
        loaderProps: {
          type: "bars",
        },
        transitionProps: {},
      },
    }),
    DrawerTitle: DrawerTitle.extend({
      defaultProps: {
        fw: "bold",
        fz: "xl",
      },
    }),
    DrawerContent: DrawerContent.extend({
      defaultProps: {
        p: 0,
      },
    }),
    ModalTitle: ModalTitle.extend({
      defaultProps: {
        fz: "lg",
        fw: "bold",
      },
    }),
    ModalContent: ModalContent.extend({
      defaultProps: {
        p: 0,
      },
    }),
    Notification: Notification.extend({
      styles: {
        title: {
          fontWeight: "bold",
          fontSize: "lg",
          marginBottom: 5,
        },
      },
    }),
    Indicator: Indicator.extend({
      defaultProps: {
        radius: "xs",
      },
    }),
    Badge: Badge.extend({
      defaultProps: {
        radius: "sm",
        variant: "light",
      },
    }),
    Paper: Paper.extend({
      defaultProps: {
        shadow: "md",
        withBorder: true,
        p: "md",
      },
    }),
    Anchor: Anchor.extend({
      defaultProps: {
        size: "sm",
      },
    }),
    Checkbox: Checkbox.extend({
      styles: {
        input: {
          cursor: "pointer",
        },
      },
    }),
  },
});
export const AppProviders: FC = () => (
  <>
    <ColorSchemeScript defaultColorScheme="light" />
    <MantineProvider defaultColorScheme="light" theme={theme}>
      <ReactKeycloakProvider authClient={keycloak}>
        <AuthProvider>
          <Notifications autoClose={2000} position="top-right" />
          <ModalsProvider>
            <I18nextProvider i18n={i18n}>
              <AppRoutes />
            </I18nextProvider>
          </ModalsProvider>
        </AuthProvider>
      </ReactKeycloakProvider>
    </MantineProvider>
  </>
);
