import axios from 'axios';

import { BuilderUserEntity, SubUserEntity, UserRepresentation } from 'sublinx-autopilot-shared';
import keycloak from '../auth/keycloak';

const AuthService = {
  fetchUsers: async (
    search?: string,
    max: number = 20,
    page: number = 0
  ): Promise<UserRepresentation[]> => {
    const response = await axios.get('/common/keycloak/users', {
      params: {
        search,
        max,
        first: page,
      },
    });
    return response.data;
  },
  fetchEvents: async (
    user: SubUserEntity | BuilderUserEntity,
    page: any,
    type: string
  ): Promise<Event[]> => {
    const response = await axios.get('/common/keycloak/events', {
      params: {
        type:
          type === 'ALL'
            ? ['LOGIN', 'LOGOUT', 'UPDATE_PASSWORD']
            : type === 'LOGINS'
              ? ['LOGIN', 'LOGOUT']
              : ['UPDATE_PASSWORD'],
        userName: user.username,
        max: 10,
        first: page || 0,
      },
    });
    return response.data;
  },
  resetPassword: async (userName: string, password: string) => {
    const response = await axios.put('/common/keycloak/update-password', {
      userName,
      password,
    });
    return response.data;
  },
  sessions: {
    fetchSessions: async (user: SubUserEntity | BuilderUserEntity): Promise<Session[]> => {
      const response = await axios.get('/common/keycloak/sessions', {
        params: {
          userName: user.username,
        },
      });
      return response.data;
    },
    deleteSession: async (sessionId: string) => {
      const response = await axios.delete(`/common/keycloak/sessions/${sessionId}`);
      return response.data;
    },
  },
  roles: {
    hasRole: (role: string): boolean =>
      keycloak.tokenParsed?.realm_access?.roles?.includes(role) || false,
  },
  tokens: {
    updateToken: async (minValidity: number = 70): Promise<boolean> => {
      try {
        const refreshed = await keycloak.updateToken(minValidity);
        return refreshed;
      } catch (error) {
        console.error('Failed to refresh token:', error);
        // Token refresh failed - redirect to login
        keycloak.login();
        return false;
      }
    },

    getTokenExpiration: (): number => {
      return (keycloak.tokenParsed?.exp ?? 0) * 1000; // Convert to milliseconds
    },

    getRefreshTokenExpiration: (): number => {
      return (keycloak.refreshTokenParsed?.exp ?? 0) * 1000; // Convert to milliseconds
    }
  },
};

export default AuthService;

export interface Event {
  time: number;
  type: string;
  realmId: string;
  clientId: string;
  userId: string;
  sessionId: string;
  ipAddress: string;
  details: Details;
}
export interface Details {
  auth_method: string;
  auth_type: string;
  response_type: string;
  redirect_uri: string;
  consent: string;
  code_id: string;
  response_mode: string;
  username: string;
}

export interface Session {
  id: string;
  username: string;
  userId: string;
  ipAddress: string;
  start: number;
  lastAccess: number;
  clients: any[];
}
