import {
  ActionIcon,
  Box,
  Button,
  Group,
  LoadingOverlay,
  Select,
  Stack,
  TextInput,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { IconAi } from "@tabler/icons-react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { DocPageEntity, DocPagePatch } from "sublinx-autopilot-shared";
import { AIService } from "../../services/AIService";
import { DocumentationService } from "../../services/DocumentationService";
import { useDocumentationStore } from "../../stores/DocumentationStore";

interface PageModalProps {
  currentPage?: DocPageEntity;
}

export function PageModal({ currentPage }: PageModalProps) {
  const [loading, setLoading] = useState(false);
  const documentationStore = useDocumentationStore();
  const location = useLocation();

  const form = useForm<DocPagePatch>({
    initialValues: {
      pathname: currentPage?.pathname || location.pathname,
      title: currentPage?.title || documentationStore.currentPage || "",
      body: currentPage?.body || "",
      orgType: currentPage?.orgType || "BUILDER",
    },
    validate: {
      title: (value) => (value ? null : "Title is required"),
      body: (value) => (value ? null : "Body is required"),
      pathname: (value) => (value ? null : "Pathname is required"),
      orgType: (value) => (value ? null : "Organization Type is required"),
    },
  });

  const handleSubmit = async (values: DocPagePatch) => {
    try {
      setLoading(true);
      if (currentPage) {
        await DocumentationService.updateDocPage(currentPage.pathname!, values);
      } else {
        await DocumentationService.createDocPage(values);
      }
      notifications.show({
        title: "Success",
        message: currentPage
          ? "Page updated successfully"
          : "Page created successfully",
        color: "green",
      });
      modals.closeAll();
      form.reset();
    } catch (error) {
      notifications.show({
        title: "Error",
        message: `Failed to ${currentPage ? "update" : "create"} page`,
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box title={currentPage ? "Edit Page" : "Create New Page"} size="lg">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          {loading && <LoadingOverlay visible />}

          <TextInput
            label="Title"
            placeholder="Title (e.g. Scheduling Documentation)"
            required
            {...form.getInputProps("title")}
          />

          <Select
            label="Organization Type"
            placeholder="Select organization type"
            required
            data={[
              { value: "BUILDER", label: "Builder" },
              { value: "SUB", label: "Subcontractor" },
              { value: "BUILDER_SUB", label: "Both" },
            ]}
            {...form.getInputProps("orgType")}
          />

          <Textarea
            autosize
            resize="vertical"
            label="Description"
            placeholder="Description of the page"
            minRows={4}
            required
            {...form.getInputProps("body")}
          />
          <Group justify="flex-end">
            <Tooltip label="Rewrite text using AI">
              <ActionIcon
                onClick={async () => {
                  if (form.values.body) {
                    setLoading(true);
                    const aiText = await AIService.generateText(
                      form.values.body,
                    );
                    form.setFieldValue("body", aiText);
                    setLoading(false);
                  } else {
                    notifications.show({
                      title: "Error",
                      message: "Text is required",
                      color: "red",
                    });
                  }
                }}
              >
                <IconAi size={18} />
              </ActionIcon>
            </Tooltip>
          </Group>

          <TextInput
            disabled
            label="Pathname"
            placeholder="Pathname (e.g. /scheduling)"
            required
            {...form.getInputProps("pathname")}
          />

          <Group justify="flex-end" mt="md">
            <Button variant="subtle" onClick={modals.closeAll}>
              Cancel
            </Button>
            <Button type="submit">
              {currentPage ? "Update Page" : "Create Page"}
            </Button>
          </Group>
        </Stack>
      </form>
    </Box>
  );
}
