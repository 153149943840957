import axios from 'axios';
import { DocPageEntity, DocPagePatch, DocSectionEntity, DocSectionPatch } from 'sublinx-autopilot-shared';

export const DocumentationService = {
    async getDocPage(pathname: string): Promise<DocPageEntity> {
        const response = await axios.get('/common/doc-pages', {
            params: {
                pathname
            }
        });
        return response.data;
    },

    async createDocPage(docPage: DocPagePatch): Promise<DocPageEntity> {
        const response = await axios.post('/common/doc-pages', docPage);
        return response.data;
    },

    async deleteDocPage(id: number): Promise<void> {
        await axios.delete(`/common/doc-pages/${id}`);
    },

    async updateDocPage(pathname: string, docPage: DocPagePatch): Promise<DocPageEntity> {
        const response = await axios.patch(`/common/doc-pages`, docPage, {
            params: {
                pathname
            }
        });
        return response.data;
    },

    async createDocSection(pageId: number, section: DocSectionPatch): Promise<DocSectionEntity> {
        const response = await axios.post(`/common/doc-pages/${pageId}/sections`, section);
        return response.data;
    },

    async deleteDocSection(sectionId: number): Promise<void> {
        await axios.delete(`/common/doc-pages/sections/${sectionId}`);
    },

    async updateDocSection(sectionId: number, section: DocSectionPatch): Promise<DocSectionEntity> {
        const response = await axios.patch(`/common/doc-pages/sections/${sectionId}`, section);
        return response.data;
    },

    async reorderDocSection(sectionId: number, direction: 'up' | 'down'): Promise<void> {
        await axios.patch(`/common/doc-pages/sections/${sectionId}/reorder`, {}, {
            params: {
                direction
            }
        });
    }
}