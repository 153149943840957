import {
  Accordion,
  Alert,
  Button,
  Divider,
  Drawer,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import {
  IconArrowDown,
  IconArrowUp,
  IconEdit,
  IconFilePlus,
} from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import keycloak from "../../auth/keycloak";
import { DocumentationService } from "../../services/DocumentationService";
import { PageModal } from "./DocumentationDrawer.PageModal.component";
import { SectionModal } from "./DocumentationDrawer.SectionModal.component";
import { useDocumentationStore } from "../../stores/DocumentationStore";
import { CodeHighlight } from "@mantine/code-highlight";

interface DocumentationDrawerProps {
  opened: boolean;
  onClose: () => void;
}

export function DocumentationDrawer({
  opened,
  onClose,
}: DocumentationDrawerProps) {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const documentationStore = useDocumentationStore();
  const docPageQuery = useQuery({
    queryKey: ["doc-page", location.pathname, { opened }],
    queryFn: () => DocumentationService.getDocPage(location.pathname),
  });

  useEffect(() => {
    if (docPageQuery.data) {
      documentationStore.setCurrentPath(location.pathname);
      documentationStore.setDocPage(docPageQuery.data);
    }
  }, [docPageQuery.data]);

  const handleSectionDelete = async (id: string) => {
    modals.openConfirmModal({
      title: "Delete Section",
      children: "Are you sure you want to delete this section?",
      labels: {
        cancel: "Cancel",
        confirm: "Delete",
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        try {
          setLoading(true);
          await DocumentationService.deleteDocSection(parseInt(id, 10));
          docPageQuery.refetch();
          notifications.show({
            title: "Success",
            message: "Section deleted",
            color: "green",
          });
        } catch (error) {
          notifications.show({
            title: "Error",
            message: "Failed to delete section",
            color: "red",
          });
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const handleSectionReorder = async (id: number, direction: "up" | "down") => {
    try {
      setLoading(true);
      await DocumentationService.reorderDocSection(id, direction);
      docPageQuery.refetch();
      notifications.show({
        title: "Success",
        message: "Section reordered successfully",
        color: "green",
      });
    } catch (error) {
      notifications.show({
        title: "Error",
        message: "Failed to reorder section",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      p={0}
      withinPortal
      opened={opened}
      onClose={onClose}
      title={
        docPageQuery.data?.title
          ? docPageQuery.data?.title
          : "Page Documentation"
      }
      position="right"
    >
      <Stack>
        <Text>{docPageQuery.data?.body}</Text>

        {loading && <LoadingOverlay visible />}
        {keycloak.realmAccess?.roles.includes("SUBLINX_ADMIN") && (
          <>
            <Group>
              <Button
                size="xs"
                variant="light"
                leftSection={<IconEdit size={18} />}
                onClick={() =>
                  modals.open({
                    id: "page-modal",
                    title: "Edit Page",
                    onClose: () => docPageQuery.refetch(),
                    children: <PageModal currentPage={docPageQuery.data} />,
                  })
                }
              >
                {docPageQuery.data ? "Edit Page" : "Create Page"}
              </Button>
              {docPageQuery.data && (
                <Button
                  size="xs"
                  variant="light"
                  leftSection={<IconFilePlus size={18} />}
                  onClick={() =>
                    modals.open({
                      title: "Add Section",
                      onClose: () => docPageQuery.refetch(),
                      children: (
                        <SectionModal currentPage={docPageQuery.data} />
                      ),
                    })
                  }
                >
                  Add Section
                </Button>
              )}
            </Group>

            {docPageQuery.data && docPageQuery.data.sections?.length === 0 && (
              <Alert color="yellow">
                This page has no sections yet. Click "Add Section" to create
                one.
              </Alert>
            )}
          </>
        )}
      </Stack>
      <Accordion
        mt="md"
        variant="separated"
        multiple
        defaultValue={docPageQuery.data?.sections?.map(
          (section) => `doc-section-${section.id?.toString()}`,
        )}
      >
        {docPageQuery.data?.sections?.map((section, index) => (
          <Accordion.Item
            key={section.id}
            value={`doc-section-${section.id?.toString()!}`}
          >
            <Accordion.Control>
              <Text fw="bold" size="lg">
                {section.title}
              </Text>
            </Accordion.Control>
            <Accordion.Panel>
              <CodeHighlight
                withCopyButton={false}
                code={section.body || ""}
                language="markdown"
              />
              {keycloak.realmAccess?.roles.includes("SUBLINX_ADMIN") && (
                <>
                  <Divider label="Admin Only" my="sm" />
                  <Group grow>
                    <Button
                      variant="light"
                      size="xs"
                      leftSection={<IconEdit size={16} />}
                      onClick={() =>
                        modals.open({
                          title: "Edit Section",
                          onClose: () => docPageQuery.refetch(),
                          children: (
                            <SectionModal
                              currentPage={docPageQuery.data!}
                              currentSection={section}
                            />
                          ),
                        })
                      }
                    >
                      Edit
                    </Button>

                    <Button
                      variant="light"
                      size="xs"
                      color="red"
                      onClick={() =>
                        handleSectionDelete(section.id!.toString())
                      }
                    >
                      Delete
                    </Button>
                    <Button.Group>
                      <Tooltip label="Move Section Up">
                        <Button
                          size="xs"
                          variant="default"
                          disabled={index === 0}
                          onClick={() =>
                            handleSectionReorder(section.id!, "up")
                          }
                        >
                          <IconArrowUp size={16} />
                        </Button>
                      </Tooltip>
                      <Tooltip label="Move Section Down">
                        <Button
                          size="xs"
                          variant="default"
                          disabled={
                            index === docPageQuery.data.sections!.length - 1
                          }
                          onClick={() =>
                            handleSectionReorder(section.id!, "down")
                          }
                        >
                          <IconArrowDown size={16} />
                        </Button>
                      </Tooltip>
                    </Button.Group>
                  </Group>
                </>
              )}
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Drawer>
  );
}
