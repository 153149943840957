import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../auth/useAuth";
import { PageHeader } from "../components/PageHeader";

const appTitle = import.meta.env.VITE_APP_TITLE;

export function usePageData(props: {
  isPageLoading: boolean;
  pageTitle?: string;
  defaultSelectedTab?: string;
}) {
  const [title, setTitle] = useState<string>(props.pageTitle || appTitle);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(
    props.isPageLoading || false,
  );
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { authUser } = useAuth();
  const selectedTab = searchParams.get("t") || props.defaultSelectedTab;
  const setSelectedTab = (tab: string | null) => {
    if (tab) {
      setSearchParams({ t: tab });
    }
  };

  useEffect(() => {
    if (title) {
      document.title = `${appTitle} - ${title}`;
    }
  }, [title]);

  return {
    title,
    setTitle,
    isPageLoading,
    setIsPageLoading,
    PageHeader,
    t,
    i18n,
    navigate,
    authUser,
    selectedTab,
    setSelectedTab,
  };
}
