import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Group,
  LoadingOverlay,
  Stack,
  TextInput,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { IconAi } from "@tabler/icons-react";
import { useState } from "react";
import {
  DocPageEntity,
  DocSectionEntity,
  DocSectionPatch,
} from "sublinx-autopilot-shared";
import { AIService } from "../../services/AIService";
import { DocumentationService } from "../../services/DocumentationService";

interface SectionModalProps {
  currentPage: DocPageEntity;
  currentSection?: DocSectionEntity;
}

export function SectionModal({
  currentPage,
  currentSection,
}: SectionModalProps) {
  const [loading, setLoading] = useState(false);

  const form = useForm<DocSectionPatch>({
    initialValues: {
      title: currentSection?.title || "",
      body: currentSection?.body || "",
      autoLoad: currentSection?.autoLoad || false,
    },
    validate: {
      title: (value) => (value ? null : "Title is required"),
      body: (value) => (value ? null : "Body is required"),
    },
  });

  const handleSubmit = async (values: DocSectionPatch) => {
    try {
      setLoading(true);
      if (currentSection) {
        await DocumentationService.updateDocSection(currentSection.id!, values);
      } else {
        await DocumentationService.createDocSection(currentPage.id!, values);
      }
      notifications.show({
        title: "Success",
        message: currentSection
          ? "Section updated successfully"
          : "Section created successfully",
        color: "green",
      });
      modals.closeAll();
    } catch (error) {
      notifications.show({
        title: "Error",
        message: `Failed to ${currentSection ? "update" : "create"} section`,
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          {loading && <LoadingOverlay visible />}

          <TextInput
            label="Section Title"
            placeholder="Enter section title"
            required
            {...form.getInputProps("title")}
          />

          <Textarea
            autosize
            resize="vertical"
            label="Section Content"
            placeholder="Enter section content"
            minRows={4}
            required
            {...form.getInputProps("body")}
          />
          <Group justify="flex-end">
            <Tooltip label="Rewrite text using AI">
              <ActionIcon
                onClick={async () => {
                  if (form.values.body) {
                    setLoading(true);
                    const aiText = await AIService.generateText(
                      form.values.body,
                    );
                    form.setFieldValue("body", aiText);
                    setLoading(false);
                  } else {
                    notifications.show({
                      title: "Error",
                      message: "Text is required",
                      color: "red",
                    });
                  }
                }}
              >
                <IconAi size={18} />
              </ActionIcon>
            </Tooltip>
          </Group>
          <Checkbox
            label="Show this section on the page"
            description="When enabled, this section will appear at the top of page as an alert"
            {...form.getInputProps("autoLoad", { type: "checkbox" })}
          />

          <Group justify="flex-end" mt="md">
            <Button variant="subtle" onClick={() => modals.closeAll()}>
              Cancel
            </Button>
            <Button type="submit">
              {currentSection ? "Update Section" : "Add Section"}
            </Button>
          </Group>
        </Stack>
      </form>
    </Box>
  );
}
