import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import keycloak from "../../../auth/keycloak";
import { MobileApp } from "../../../pages/admin/mobile/MobileApp";
import { Logout } from "../../../pages/auth/Logout";
import { ErrorPage404 } from "../../../pages/error/404";
import { Layout } from "../Layout";
import { JobListFilteredLazy, JobManageLazy } from "./_builderRoutes";

export const SubdivisionManageLazy = lazy(() =>
  import(
    "../../../pages/builder-pages/projects/subdivisions/SubdivisionManage"
  ).then(async ({ SubdivisionManage }) => ({
    default: SubdivisionManage,
  })),
);
export const SubdivisionListLazy = lazy(() =>
  import(
    "../../../pages/builder-pages/projects/subdivisions/SubdivisionList"
  ).then(async ({ SubdivisionList }) => ({
    default: SubdivisionList,
  })),
);

const DashboardLazy = lazy(() =>
  import("../../../pages/dashboard/Dashboard").then(({ Dashboard }) => ({
    default: Dashboard,
  })),
);

export const JobsOverviewLazy = lazy(() =>
  import("../../../pages/common/jobs/Jobs.Overview.page").then(
    async ({ JobsOverview }) => ({
      default: JobsOverview,
    }),
  ),
);
const SchedulingLazy = lazy(() =>
  import("../../../pages/scheduling/SchedulingJobList").then(
    ({ SchedulingJobList }) => ({
      default: SchedulingJobList,
    }),
  ),
);
const UserManageLazy = lazy(() =>
  import("../../../pages/common/users/manage/UserManage").then(
    ({ UserManage: UserProfile }) => ({
      default: UserProfile,
    }),
  ),
);
const UserListLazy = lazy(() =>
  import("../../../pages/common/users/list/UserList").then(
    async ({ UserList }) => ({
      default: UserList,
    }),
  ),
);
const AdministrationLandingLazy = lazy(() =>
  import("../../../pages/common/administration/AdministrationLanding").then(
    async ({ AdministrationLanding }) => ({
      default: AdministrationLanding,
    }),
  ),
);
const AdministrationBuilderConnectionsLazy = lazy(() =>
  import("../../../pages/common/administration/BuilderConnections").then(
    async ({ BuilderConnections }) => ({
      default: BuilderConnections,
    }),
  ),
);
const AdministrationTasksLazy = lazy(() =>
  import("../../../pages/common/administration/AdministrationTasks").then(
    async ({ AdministrationTasks }) => ({
      default: AdministrationTasks,
    }),
  ),
);
const RoleListLazy = lazy(() =>
  import("../../../pages/common/users/roles/RoleList").then(
    async ({ RoleList }) => ({
      default: RoleList,
    }),
  ),
);
const RoleManageLazy = lazy(() =>
  import("../../../pages/common/users/roles/RoleManage").then(
    async ({ RoleManage }) => ({
      default: RoleManage,
    }),
  ),
);
const OrgAdminManageLazy = lazy(() =>
  import("../../../pages/common/org/OrgAdminManage").then(
    async ({ OrgAdminManage }) => ({
      default: OrgAdminManage,
    }),
  ),
);

const PlanListWrapperLazy = lazy(() =>
  import("../../../pages/sub-pages/plans/PlanListWrapper").then(
    async ({ PlanListWrapper }) => ({
      default: PlanListWrapper,
    }),
  ),
);
const PlanManageWrapperLazy = lazy(() =>
  import("../../../pages/sub-pages/plans/PlanManageWrapper").then(
    async ({ PlanManageWrapper }) => ({
      default: PlanManageWrapper,
    }),
  ),
);

const SubcontractorRoutes = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route path={"/*"} element={<Navigate to="/" />} />
      <Route path="/" element={<DashboardLazy />} />
      <Route path="/scheduling" element={<SchedulingLazy />} />
      <Route path="/jobs">
        <Route index element={<JobsOverviewLazy />} />
        <Route path="dashboard" element={<JobsOverviewLazy />} />
        <Route path="list" element={<JobListFilteredLazy />} />
        <Route path=":jobId" element={<JobManageLazy />} />
        <Route path="new" element={<JobManageLazy />} />
      </Route>
      <Route path="/users">
        <Route index element={<UserListLazy />} />
        <Route path=":userId" element={<UserManageLazy />} />
        <Route path="new" element={<UserManageLazy />} />
        <Route path="roles">
          <Route index element={<RoleListLazy />} />
          <Route path="new" element={<RoleManageLazy />} />
          <Route path=":roleId" element={<RoleManageLazy />} />
        </Route>
      </Route>
      <Route path="/plans">
        <Route index element={<PlanListWrapperLazy />} />
        <Route path=":planId" element={<PlanManageWrapperLazy />} />
        <Route path="new" element={<PlanManageWrapperLazy />} />
      </Route>
      <Route path="/org-admin">
        <Route index element={<OrgAdminManageLazy />} />
      </Route>
      <Route path="/administration">
        <Route index element={<AdministrationLandingLazy />} />
        <Route
          path="builders"
          element={<AdministrationBuilderConnectionsLazy />}
        />
        <Route path="tasks" element={<AdministrationTasksLazy />} />
      </Route>
      {keycloak.realmAccess?.roles.includes("SUBLINX_ADMIN") && (
        <Route path="/admin">
          <Route path="mobile" element={<MobileApp />} />
          <Route path="subcontractors">
            <Route index element={<SubcontractorListLazy />} />
            <Route path="new" element={<SubcontractorManageLazy />} />
            <Route path=":id" element={<SubcontractorManageLazy />} />
          </Route>
          <Route path="builders">
            <Route index element={<BuilderListLazy />} />
            <Route path="new" element={<BuilderManageLazy />} />
            <Route path=":id" element={<BuilderManageLazy />} />
          </Route>
          <Route path="trades">
            <Route index element={<TradeListLazy />} />
            <Route path="new" element={<TradeManageLazy />} />
            <Route path=":id" element={<TradeManageLazy />} />
          </Route>
          <Route path="build-schedules">
            <Route index element={<BuildScheduleImportLazy />} />
          </Route>
          <Route path="tasks">
            <Route index element={<TradeTaskReorderLazy />} />
          </Route>
          <Route path="users">
            <Route index element={<AdminUserListLazy />} />
          </Route>
        </Route>
      )}
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<ErrorPage404 />} />
    </Route>
  </Routes>
);
export default SubcontractorRoutes;

const SubcontractorListLazy = lazy(() =>
  import("../../../pages/admin/subcontractors/list/SubcontractorList").then(
    async ({ SubcontractorList }) => ({ default: SubcontractorList }),
  ),
);
const SubcontractorManageLazy = lazy(() =>
  import("../../../pages/admin/subcontractors/manage/SubcontractorManage").then(
    async ({ SubcontractorManage }) => ({ default: SubcontractorManage }),
  ),
);

const TradeTaskReorderLazy = lazy(() =>
  import("../../../pages/admin/tasks/manage/TradeTaskReorder").then(
    async ({ TradeTaskReorder }) => ({
      default: TradeTaskReorder,
    }),
  ),
);
const BuildScheduleImportLazy = lazy(() =>
  import("../../../pages/admin/build-schedules/BuildScheduleImport").then(
    async ({ BuildScheduleImport }) => ({
      default: BuildScheduleImport,
    }),
  ),
);

const AdminUserListLazy = lazy(() =>
  import("../../../pages/admin/users/KeycloakUserList").then(
    async ({ KeycloakUserList }) => ({
      default: KeycloakUserList,
    }),
  ),
);

const BuilderListLazy = lazy(() =>
  import("../../../pages/admin/builders/list/BuilderList").then(
    async ({ BuilderList }) => ({
      default: BuilderList,
    }),
  ),
);
const BuilderManageLazy = lazy(() =>
  import("../../../pages/admin/builders/manage/BuilderManage").then(
    async ({ BuilderManage }) => ({
      default: BuilderManage,
    }),
  ),
);
const TradeListLazy = lazy(() =>
  import("../../../pages/admin/trades/list/TradeList").then(
    async ({ TradeList }) => ({
      default: TradeList,
    }),
  ),
);
const TradeManageLazy = lazy(() =>
  import("../../../pages/admin/trades/manage/TradeManage").then(
    async ({ TradeManage }) => ({
      default: TradeManage,
    }),
  ),
);
