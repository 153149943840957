import { DocPageEntity } from 'sublinx-autopilot-shared';
import { create } from 'zustand';

interface DocumentationState {
    currentPage: string;
    setCurrentPage: (pageName: string) => void;

    currentPath: string;
    setCurrentPath: (path: string) => void;

    docPage: DocPageEntity | null;
    setDocPage: (docPage: DocPageEntity | null) => void;
    clearDocPage: () => void;

    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;

    hasError: boolean;
    setHasError: (hasError: boolean) => void;
}

export const useDocumentationStore = create<DocumentationState>((set) => ({
    currentPage: '',
    setCurrentPage: (pageName: string) => set({ currentPage: pageName }),

    currentPath: '',
    setCurrentPath: (path: string) => set({ currentPath: path }),

    docPage: null,
    setDocPage: (docPage: DocPageEntity | null) => set({ docPage, hasError: false }),
    clearDocPage: () => set({ docPage: null }),

    isLoading: false,
    setIsLoading: (isLoading: boolean) => set({ isLoading }),

    hasError: false,
    setHasError: (hasError: boolean) => set({ hasError }),
}));
